<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <!-- Bookmarks Container -->
<!--      <bookmarks />-->
      <h2>{{ month }}</h2>
    </div>

    <div class="d-flex">
      <div>
        <div class="d-flex align-items-center mr-2">
          <b-avatar
              variant="light-primary"
              rounded
          >
            <feather-icon
                icon="DiscIcon"
                size="18"
            />
          </b-avatar>
          <div class="ml-1">
            <h5 class="mb-0">
              {{ albumStats.rated }}<span class="d-none d-lg-inline d-md-inline d-sm-inline"> / {{ albumStats.total }}</span>
            </h5>
            <small class="d-none d-lg-inline d-md-inline">Альбомов</small>
          </div>
        </div>
      </div>
      <div>
        <div class="d-flex align-items-center mr-2">
          <b-avatar
              variant="light-primary"
              rounded
          >
            <feather-icon
                icon="YoutubeIcon"
                size="18"
            />
          </b-avatar>
          <div class="ml-1">
            <h5 class="mb-0">
              {{ videoStats.rated }}<span class="d-none d-lg-inline d-md-inline d-sm-inline"> / {{ videoStats.total }}</span>
            </h5>
            <small class="d-none d-lg-inline d-md-inline">Клипов</small>
          </div>
        </div>
      </div>
      <div>
        <div class="d-flex align-items-center mr-2">
          <b-avatar
              variant="light-primary"
              rounded
          >
            <feather-icon
                icon="MusicIcon"
                size="18"
            />
          </b-avatar>
          <div class="ml-1">
            <h5 class="mb-0">
              {{ songStats.rated }}<span class="d-none d-lg-inline d-md-inline d-sm-inline"> / {{ songStats.total }}</span>
            </h5>
            <small class="d-none d-lg-inline d-md-inline">Песен</small>
          </div>
        </div>
      </div>
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
<!--      <locale />-->
      <dark-toggler class="d-none d-lg-block" />
<!--      <search-bar />-->
<!--      <cart-dropdown />-->
<!--      <notification-dropdown />-->
      <user-dropdown />
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BAvatar,
  BLink, BNavbarNav,
} from 'bootstrap-vue'
import Bookmarks from './components/Bookmarks.vue'
import Locale from './components/Locale.vue'
import SearchBar from './components/SearchBar.vue'
import DarkToggler from './components/DarkToggler.vue'
import CartDropdown from './components/CartDropdown.vue'
import NotificationDropdown from './components/NotificationDropdown.vue'
import UserDropdown from './components/UserDropdown.vue'

export default {
  components: {
    BLink,

    // Navbar Components
    BNavbarNav,
    Bookmarks,
    Locale,
    SearchBar,
    DarkToggler,
    CartDropdown,
    NotificationDropdown,
    UserDropdown,
    BAvatar
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      albumStats: {
        total: 0,
        rated: 0,
      },
      videoStats: {
        total: 0,
        rated: 0,
      },
      songStats: {
        total: 0,
        rated: 0,
      },
      month: "",
    }
  },
  created() {
    this.$http.get('/stats').then(res => {
      this.albumStats = res.data.albumStats
      this.videoStats = res.data.videoStats
      this.songStats = res.data.songStats
      this.month = res.data.month
    })
  },
}
</script>
